import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from "../components/header"
import Heading from "../components/heading"

const BlogPage = ({data}) => {
  const entry = data.prismicBlogPage
  const entries = data.allPrismicBlog
  if (!entry) return null
  return (
    <div>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image={entry.data.meta_image.url} pathname={linkResolver(entry.data)} />
      <Header logo='cuddon' />
      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'heading') {
          return (
            <div key={slice.id}>
              <Heading primary={slice.primary} />
            </div>
          )
        }
      })}
      <div className="w-full py-12 border-grey-c border-b-2">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
            {entries.edges.map((entry,i) => {
              return (
              <div key={i} className="group" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
                <div className="pb-3 bg-blue">
                  <Link to={`/blog/${entry.node.uid}/`}>
                    <GatsbyImage className="aspect-w-5 aspect-h-3" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.node.data.image.gatsbyImageData} alt={entry.node.data.image.alt ? entry.node.data.image.alt : ''} />
                  </Link>
                </div>
                <div className="mt-6">
                  <Link to={`/blog/${entry.node.uid}/`} className="font-brand font-bold text-3xl text-blue border-b-4 border-white group-hover:border-blue inline-block">
                    {entry.node.data.heading}
                  </Link>
                </div>
                <div className="mt-6">
                  <div className="font-sans prose prose-lg leading-normal mb-3">
                    <h6>{entry.node.data.date}</h6>
                    <p>{entry.node.data.snippet}</p>
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
query {
  prismicBlogPage {
    data {
      meta_description
      page_title
      meta_image {
        url
      }
      body {
        ... on PrismicBlogPageDataBodyHeading {
          id
          slice_type
          primary {
            button1
            heading1
            text1 {
              richText
            }
            page_link1 {
              url
              uid
              type
            }
            parent {
              url
              uid
              type
              link_type
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  allPrismicBlog(sort: {fields: data___date, order: DESC}) {
    edges {
      node {
        uid
        data {
          date(formatString: "MMM DD, YYYY")
          heading
          text {
            richText
          }
          image {
            gatsbyImageData
            alt
          }
          snippet
        }
      }
    }
  }
}
`

export default BlogPage
